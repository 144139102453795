<template>
  <div class="ma-bottom">
    <div class="red-title font18 color-white align-center">{{title}}</div>
    <div class="bg-white">
      <ul :class="title=='大会服务'?'list-xx pl45':'list-xx'">
        <li v-for="(item,index) in list" :key="index" :class="item.id==keyid?'on':''">
          <router-link :to="{name:routername,query:{id:item.id}}"  @click.native="refresh">{{item.vName}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "leftmenulist",
  data() {
    return {
      list: [],
      routername: "",
    };
  },
  props: ["title", "keyid"],
  inject:['reload'],
  mounted() {
    if (this.title == "大会服务") {
      this.routername = "mediaFocus";
      this.list = [
        { vName: "酒店服务", id: 1 },
        { vName: "行程路线", id: 2 },
        { vName: "注意事项", id: 3 }
      ];
    }else if(this.title == "大会集锦") {
      //大会集锦
      this.list = [
        { vName: "图片集锦", id: 1 },
        { vName: "视频集锦", id: 2 }      
      ];
       this.routername = "specimens";
      // this.routername = "forumdetails";
     
    } else {
      //论坛
      this.routername = "forumdetails";
      // this.getinformation();
    }
  },
  methods: {
    // getinformation() {
    //   this.$axios
    //     .post("/api/app/exhibitionForum/getExhibitionForumList", {})
    //     .then(res => {
    //       if (res[0].length > 0) {
    //         let infordata = res[0];
    //         this.list = infordata;
    //       }
    //     });
    // },
    refresh:function(){
      // debugger
// 　　　　　　this.reload()
　　　　}
  }
};
</script>
<style scoped>
.ma-bottom {
  margin-bottom: 15px;
}
.red-title {
  height: 50px;
  background-color: #073290;
  line-height: 50px;
}
.bg-white {
  padding: 10px 0;
}
.list-xx li {
  display: block;
  padding: 15px 15px 15px 30px;
  position: relative;
  text-align: left;
}
.list-xx li::before {
  position: absolute;
  left: 15px;
  top: 22px;
  content: "";
  width: 5px;
  height: 5px;
  background: #a7a7a7;
}
.list-xx li.on::before,
.list-xx li:hover::before {
  background: #e70012;
}

.list-xx a {
  line-height: 20px;
}
.list-xx li.on a,
.list-xx li:hover a {
  color: #e70012;
}
.pl45 {
  padding-left: 45px;
}
</style>