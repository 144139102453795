<template>
  <div>
    <topmenu :comname="'mediaFocus'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>大会服务</span>
      </div>
      <div class="widht200 fl">
        <leftmenulist :title="'大会服务'" :keyid="id"></leftmenulist>
        <contact></contact>
      </div>
      <div class="widht1030 bg-white padding fr" v-loading="loading">
        <!-- <div class="content" v-if="synopsis&&id==1" v-html="synopsis"></div>
        <div class="content" v-else-if="schedule&&id==2" v-html="schedule"></div>
        <div class="content" v-else-if="pdfsrc&&id==3">
          <embed :src="pdfsrc" type="application/pdf" style="overflow: auto; width: 100%; height: 800px;">
        </div> -->
        <nocont v-if="isshowcont"></nocont>
        <div v-html="synopsis"></div>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import leftmenulist from "@/components/public/menulist";
import nocont from "@/components/public/nocont";
export default {
  name: "exintroduce",
  data() {
    return {
      id: 1,
      synopsis: "", //简介
      schedule: "", //会议日程
      pdfsrc: "",
      loading: true,
      isshowcont: false
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    leftmenulist,
    nocont
  },
  created() {
    this.getinformation();
  },
  methods: {
    getinformation(id) {
      console.log(id,'id');
      this.$axios.post("/api/rally/getMeetingService", {iType:id}).then(res => {
        this.loading = false;
        if (res[0].length > 0) {
          this.synopsis = res[0][0].nvcContent
          //  console.log(res[0][0].nvcContent,354634354135);
          if (this.synopsis == "") {
            this.isshowcont = true;
          }else {
            this.isshowcont = false;
          }
        }
      });
    },
    // getproceeding() {
    //   let data = {
    //     current: 1,
    //     size: 1,
    //     isPage: true,
    //     iType: 2
    //   };
    //   this.$axios
    //     .post("/api/app/exhibitionMaterials/getExhibitionMaterialsPageList", data)
    //     .then(res => {
    //       this.loading = false;
    //       if (res[0].length > 0) {
    //         let infordata = res[0];
    //         console.log(infordata);
    //        this.pdfsrc = infordata[0].vAppend;
    //        if(this.pdfsrc==""){
    //         this.isshowcont = true;
    //        }else{
    //         this.isshowcont = false;
    //        }
    //       } 
    //     });
    // }
  },
  watch: {
    $route: function(to, from) {
      this.id = this.$route.query.id;
      if (this.id == 1 || this.id == 2 || this.id == 3) {
        this.getinformation(this.id);
      }
    }
  }
};
</script>
<style scoped>
.content {
  padding: 0 40px;
  line-height: 28px;
  color: #333;
  text-align: left;
}
.padding {
  padding: 40px 0;
}
</style>